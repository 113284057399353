import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import React, { useEffect, useRef, useState } from 'react';
import { FiUserPlus, FiSearch, FiTrash } from 'react-icons/fi';
import { FaEye } from 'react-icons/fa';
import { Link, useParams } from 'react-router-dom';

import Input from '../../../components/Input';
import Spinner from '../../../components/Spinner';
import { useToast } from '../../../hooks/toast';
import api from '../../../services/api';

import { Container, Content, RowContainer, Row, Schedule, Page, Pagination, Search, Title, ModalContent } from './styles';
import Modal from '../../../components/Modal';
import Button from '../../../components/Button';
import { convertToDate, formtarData } from '../../../utils/converter';
import { useAuth } from '../../../hooks/auth';

const nomeDiaSemana = ['Dom', ' Seg', ' Ter', ' Quar', ' Qui', ' Sex', ' Sab'];

const HabitosList: React.FC = () => {
  const { prof } = useParams();
  const { role } = useAuth();
  const formRef = useRef<FormHandles>(null);
  const { addToast } = useToast();
  const [loading, setLoading] = useState(false);
  const [list, setList] = useState<any>([]);
  const [pages, setPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [text, setText] = useState<string>('');
  const [professor, setProfessor] = useState(0);
  const [modal, setModal] = useState(false);
  const [modalAtividade, setModalAtividade] = useState(false);
  const [habito, setHabito] = useState<any>({});
  const [refresh, setRefresh] = useState(0);
  const [aula, setAula] = useState<any>({});

  useEffect(() => {
    if (prof) setProfessor(Number(prof));
  }, [prof]);

  useEffect(() => {
    setLoading(true);
    api
      .get(`habitos`)
      .then((response) => {
        setList(response.data.list);
        setCurrentPage(response.data.currentPage);
        setPages(response.data.pages);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        addToast({
          type: 'error',
          title: error.message,
        });
      });
  }, [addToast, professor, refresh]);

  const handleGotoPage = (_page: number) => {
    if (_page > pages) return;
    setLoading(true);
    const query = text ? `&Text=${text}` : '';
    api
      .get(`habitos?PageNumber=${_page}${query}`)
      .then((resp) => {
        setList(resp.data.list);
        setCurrentPage(resp.data.currentPage);
        setPages(resp.data.pages);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const handleFilter = (e: React.ChangeEvent<HTMLInputElement>) => {
    setText(e.target.value || '');
  };

  const handleSearch = (e: any) => {
    if (e.key === 'Enter') {
      setLoading(true);
      const query = text ? `&Text=${text}` : '';
      api
        .get(`habitos?PageNumber=${1}${query}`)
        .then((resp) => {
          setList(resp.data.list);
          setCurrentPage(resp.data.currentPage);
          setPages(resp.data.pages);
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
        });
    }
  };

  const handleSubmit = () => ({});

  const showModal = (item: any) => {
    setModal(true);
    setHabito(item);
  };

  const showAtividade = (id: number) => {
    api.get(`aulas/${id}`).then((response) => {
      setAula(response.data);
      setModalAtividade(true);
    });
  };

  const handleDelete = () => {
    try {
      setLoading(true);
      api.delete(`habitos/${habito.id}`).then(() => {
        setLoading(false);
        setModal(false);
        setRefresh(refresh + 1);
        addToast({
          type: 'success',
          title: 'Excluido com sucesso',
        });
      });
    } catch (error) {
      setLoading(false);
      addToast({
        type: 'error',
        title: 'Ocorreu um error ao excluir',
      });
    }
  };

  return (
    <>
      <Container>
        <Content>
          <Schedule>
            <Title>
              <h1>Hábitos</h1>
              <Link to={'/habito/novo'}>
                <FiUserPlus size={20} />
                <span>Novo</span>
              </Link>
            </Title>
            <Form
              ref={formRef}
              onSubmit={handleSubmit}
              placeholder={undefined}
              onPointerEnterCapture={undefined}
              onPointerLeaveCapture={undefined}
            >
              <Search>
                <Input name="nome" placeholder="Buscar" icon={FiSearch} onChange={handleFilter} onKeyDown={handleSearch} />
              </Search>
            </Form>
            {!loading ? (
              <>
                <RowContainer>
                  {list.map((t: any) => (
                    <Row key={t.id}>
                      <div className="flex">
                        <div className="flex-none w-14"></div>
                        <div className="grow" onClick={() => showAtividade(t.atividade)}>
                          <strong>
                            <a>
                              {t.nomeAluno}
                              {` ${t.titulo} - ${t.alunoNome} - ${t.diasSemana.map((s: any) => nomeDiaSemana[s.diaSemana]).toString()}`}
                            </a>
                          </strong>
                        </div>
                        <div className="flex-none w-14">
                          {(t.isCriador || role === 'Admin') && (
                            <div onClick={() => showModal(t)}>
                              <FiTrash size={25} color={'#ef1111'} />
                            </div>
                          )}
                        </div>
                      </div>
                    </Row>
                  ))}
                </RowContainer>
                <Pagination>
                  <Page onClick={() => handleGotoPage(currentPage - 1)}>Anterior</Page>
                  {Array.from({ length: pages < 20 ? pages : pages }, (_, i) => (
                    <Page key={i} selected={i + 1 === currentPage} onClick={() => handleGotoPage(i + 1)}>
                      <div>{i + 1}</div>
                    </Page>
                  ))}
                  <Page onClick={() => handleGotoPage(currentPage + 1)}>Próximo</Page>
                </Pagination>
              </>
            ) : (
              <>
                <br />
                <Spinner />
              </>
            )}
          </Schedule>
        </Content>
        <Modal visible={modal} close={() => setModal(false)}>
          <ModalContent>
            <p style={{ marginBottom: 20 }}>EXCLUIR</p>
            <div>
              <p>
                {`Deseja realmente excluir o habito`} <strong>{habito.titulo}</strong>
              </p>
              <p>Essa operação não pode ser desfeita</p>
              <Button onClick={handleDelete} loading={loading}>
                Confirmar
              </Button>
            </div>
          </ModalContent>
        </Modal>
        <Modal visible={modalAtividade} close={() => setModalAtividade(false)}>
          <ModalContent>
            <div style={{ marginTop: 30 }}>
              {aula.tipo === 1 && (aula.url.includes('jpg') || aula.url.includes('png')) && (
                <img src={firebaseUrl(aula)} alt="Preview" width={500} height={500} style={{ margin: 'auto' }} />
              )}

              {aula.tipo === 1 && aula.url.includes('pdf') && (
                <object data={firebaseUrl(aula)} width={600} height={600} style={{ margin: 'auto' }}>
                  pdf
                </object>
              )}
              {aula.tipo === 2 && (
                <div>
                  {aula.url.includes('youtu') ? (
                    <iframe
                      src={aula.url}
                      width="626"
                      height="440"
                      allow="autoplay; fullscreen; picture-in-picture"
                      style={{ margin: 'auto' }}
                      title={aula.nome}
                    ></iframe>
                  ) : aula.url.includes('player.vimeo') ? (
                    <iframe
                      src={aula.url}
                      width="626"
                      height="440"
                      allow="autoplay; fullscreen; picture-in-picture"
                      style={{ margin: 'auto' }}
                      title={aula.nome}
                    ></iframe>
                  ) : (
                    <iframe
                      src={'https://player.vimeo.com/video/' + aula.url.split(`/`)[3] + '?h=' + aula.url.split(`/`)[4]}
                      width="626"
                      height="440"
                      allow="autoplay; fullscreen; picture-in-picture"
                      style={{ margin: 'auto' }}
                      title={aula.nome}
                    ></iframe>
                  )}
                </div>
              )}
              {aula.tipo === 3 && (
                <audio controls style={{ margin: 'auto' }}>
                  <source src={firebaseUrl(aula)} type="audio/wav" />
                  Your browser does not support the audio element.
                </audio>
              )}
            </div>
          </ModalContent>
        </Modal>
      </Container>
    </>
  );
};

export default HabitosList;

const firebaseUrl = (aula: any) => {
  if (aula.tipo === 1) {
    return `https://firebasestorage.googleapis.com/v0/b/atitude-vida-saudavel.appspot.com/o/aulas%2F${aula.url}?alt=media`;
  }
  if (aula.tipo === 3) {
    return `https://firebasestorage.googleapis.com/v0/b/atitude-vida-saudavel.appspot.com/o/audios%2F${aula.url}?alt=media`;
  }
};
